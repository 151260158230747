var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Notifications" }, [
    _c("h1", { staticClass: "Tab-title" }, [_vm._v("Notifications")]),
    _c(
      "div",
      { staticClass: "Notifications-list" },
      _vm._l(_vm.notificationList, function(item, index) {
        return _c(
          "div",
          { key: index, staticClass: "Notifications-item" },
          [
            _c("div", [
              _c("h3", { staticClass: "Notifications-item-title" }, [
                _vm._v(" " + _vm._s(item.title) + " ")
              ]),
              _c("p", { staticClass: "Notifications-item-description" }, [
                _vm._v(" " + _vm._s(item.description) + " ")
              ])
            ]),
            _c("VSwitch", {
              model: {
                value: item.enabled,
                callback: function($$v) {
                  _vm.$set(item, "enabled", $$v)
                },
                expression: "item.enabled"
              }
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }