var notificationList = [
    {
        title: 'PRICING UPDATES',
        description: 'Receive Updates to product prices and rates.',
        enabled: true
    },
    {
        title: 'REGULATORY AND COMPLIANCE ALERTS',
        description: 'Receive alerts on regulatory and compliance issues/requests.',
        enabled: true
    },
    {
        title: 'LEGAL AND PRIVACY UPDATES',
        description: 'Receive updates to our legal temrs, such as a Tearms of Seervice or Privacy Notices.',
        enabled: true
    },
    {
        title: 'MARKETING EMAILS',
        description: 'Receive marketing emails and offers.',
        enabled: true
    },
    {
        title: 'BILLING NOTIFICATIONS',
        description: 'Receive all your bills monthly by email.',
        enabled: true
    },
    {
        title: 'TITLE',
        description: 'Receive Updates to product prices and rates.',
        enabled: true
    }
];
export default notificationList;
