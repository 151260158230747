import { __decorate, __extends } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import notificationList from './Notifications/notificationList';
var Notifications = (function (_super) {
    __extends(Notifications, _super);
    function Notifications() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.notificationList = notificationList;
        return _this;
    }
    Notifications = __decorate([
        Component
    ], Notifications);
    return Notifications;
}(Vue));
export default Notifications;
